import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { FormDataSchema } from "#utils/schema.js";
import { toast } from "sonner";
import clsx from "clsx";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
} from "#components/ui";
import { FormState } from "#types/subscribeTypes";

const initialState: FormState = {
  status: "idle",
  message: "",
  errors: undefined,
  fieldValues: {
    email: "",
    first_name: "",
  },
};

function SubmitButton({ pending }: { pending: boolean }) {
  return (
    <Button
      type="submit"
      size="lg"
      aria-disabled={pending}
      disabled={pending}
      className="w-full bg-accent disabled:cursor-not-allowed disabled:opacity-50 rounded-full"
    >
      {pending ? "Submitting..." : "Let me know!"}
    </Button>
  );
}

type SignupFormProps = {
  user: string;
  closeDialog?: () => void;
};

export function SignupForm({ user, closeDialog }: SignupFormProps) {
  const [formState, setFormState] = useState<FormState>(initialState);
  const hasSubmittedRef = useRef(false); // Ref to track if a toast was shown

  const form = useForm<z.infer<typeof FormDataSchema>>({
    resolver: zodResolver(FormDataSchema),
    defaultValues: {
      email: "",
      first_name: "",
    },
  });

  const { reset } = form;

  useEffect(() => {
    // Only trigger toast and reset form if this is the first time this form is being submitted
    if (!hasSubmittedRef.current && formState.status === "success") {
      reset({
        email: "",
        first_name: "",
      });
      if (closeDialog) {
        closeDialog();
      }
      toast.success(formState.message);
      hasSubmittedRef.current = true; // Set ref to true to avoid double trigger
    }

    if (!hasSubmittedRef.current && formState.status === "error") {
      if (formState.errors?.email) {
        toast.error(formState.errors.email);
      } else {
        toast.error(formState.message);
      }
      hasSubmittedRef.current = true; // Set ref to true to avoid double trigger
    }
  }, [formState, reset, closeDialog]);

  const onSubmit = async (data: z.infer<typeof FormDataSchema>) => {
    setFormState({ ...formState, status: "pending" });
    hasSubmittedRef.current = false; // Reset ref to allow triggering on new submission

    try {
      const response = await fetch("/api/sendGrid", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, user }),
      });
      const responseData = await response.json();

      if (response.ok) {
        setFormState({
          ...formState,
          status: "success",
          message: responseData.message,
        });
      } else {
        setFormState({
          ...formState,
          status: "error",
          message: responseData.message,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        setFormState({
          status: "error",
          message: error.message,
          fieldValues: data,
        });
      } else {
        setFormState({
          status: "error",
          message: "An unknown error occurred",
          fieldValues: data,
        });
      }
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-5">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="Email"
                  {...field}
                  className={clsx({
                    "border-red-600": formState.errors?.email,
                  })}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input placeholder="Name (optional)" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <div>
          <SubmitButton pending={formState.status === "pending"} />
        </div>
      </form>
      <p aria-live="polite" className="sr-only" role="status">
        {formState?.message}
      </p>
    </Form>
  );
}
